<template>
    <div class="nav-logo" :class="{ active: isReduced }" @click="onClick">
        <SvgLogo v-if="!isReduced" />
        <SvgLogoSmall v-else />
        <span class="nav-logo-text">{{ $t('general.configurator') }}</span>
    </div>
</template>

<script>
import SvgLogo from '@/assets/images/logo_mindnotes.svg'
import SvgLogoSmall from '@/assets/images/logo_mindnotes_small.svg'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
    components: {
        SvgLogo,
        SvgLogoSmall,
    },
    computed: mapState({
        isReduced: (state) => state.menu.isReduced,
        currentPage: (state) => state.page.currentPage,
    }),
    methods: {
        ...mapMutations(['clearConfiguration']),
        ...mapActions(['changePage']),
        onClick() {
            if (this.currentPage.disableConfigLinks) {
                this.clearConfiguration()
                this.$root.$emit('navigationReset')
            } else {
                this.changePage('material')
            }
        },
    },
}
</script>

<style lang="scss">
.nav-logo {
    position: relative;
    display: block;
    margin: auto;
    top: 6%;
    max-width: 267px;
    height: 70px;
    cursor: pointer;

    svg {
        fill: $color-white;
    }

    &-text {
        color: $color-gray;
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: 200;
        letter-spacing: 2px;
        white-space: nowrap;
    }

    &.active {
        max-width: 51px;
    }

    &.active & {
        &-text {
            display: none;
        }
    }

    @media (max-width: $screen-medium) {
        top: 25px;
    }
}
</style>
