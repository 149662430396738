<template>
    <nav class="navigation" :class="{ active: isReduced }">
        <button
            v-if="screenWidth > resizeMenuBreakpoint"
            class="navigation-button"
            @click="resize(!isReduced)"
        />
        <NavigationLogo />
        <NavigationList :elements="navigation" @redirect="changePage" />
    </nav>
</template>

<script>
import NavigationLogo from '@/components/navigation/NavigationLogo'
import NavigationList from '@/components/navigation/NavigationList'
import { mapState, mapMutations, mapActions } from 'vuex'
import { pages } from '@/assets/data/pages.js'

export default {
    components: {
        NavigationLogo,
        NavigationList,
    },
    data() {
        return {
            defaultPage: 'material',
        }
    },
    computed: mapState({
        isReduced: (state) => state.menu.isReduced,
        resizeMenuBreakpoint: (state) => state.menu.resizeMenuBreakpoint,
        navigation: (state) => state.page.allPages,
        currentPage: (state) => state.page.currentPage,
        screenWidth: (state) => state.page.screenWidth,
    }),
    created() {
        if (!this.navigation || !this.navigation.length > 0) {
            this.resetPagesToDefault()
        }
    },
    mounted() {
        if (!this.currentPage.id) {
            this.changePage(this.defaultPage)
        }
        this.$root.$on('navigationReset', this.navigationReset)
    },
    beforeDestroy() {
        this.$root.$off('navigationReset', this.navigationReset)
    },
    methods: {
        ...mapMutations(['resize', 'setAllPages']),
        ...mapActions(['changePage']),
        navigationReset() {
            this.resetPagesToDefault()
            this.changePage(this.defaultPage)
        },
        resetPagesToDefault() {
            this.setAllPages(JSON.parse(JSON.stringify([...pages])))
        },
    },
}
</script>

<style lang="scss">
.navigation {
    background: $color-bg-dark;
    width: 476px;
    position: relative;
    overflow-y: auto;
    transition: width 0.2s;
    overflow-x: hidden;
    flex-shrink: 0;
    @include hide-scrollbar();

    &-button {
        @include size(40px);
        position: absolute;
        right: 0;
        border: 0;
        background-color: $color-white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.2s;

        &::after {
            content: '';
            @include size(10px);
            border: solid $color-bg-dark;
            border-width: 1px 1px 0 0;
            display: block;
            transform: rotate(-135deg);
            transition: border-color 0.2s;
            margin-left: 5px;
        }

        &:hover {
            background: $color-burnt-sienna;

            &::after {
                border-color: $color-white;
            }
        }
    }

    &.active & {
        &-button::after {
            transform: rotate(45deg);
            margin-left: -5px;
        }
    }

    &.active {
        width: 128px;
        overflow: visible;
    }

    @media (max-width: $screen-page) {
        width: 420px;

        &.active {
            width: 108px;
        }
    }

    @media (max-width: $screen-tablet-large) {
        width: 340px;
    }
}
</style>
