<template>
    <div class="page-sent">
        <div>
            <div class="page-sent-box">
                <div class="page-sent-box-description">
                    <h1 v-bastards class="page-sent-box-description-title">
                        {{ $t('sent.title') }}
                    </h1>
                    <div v-bastards class="page-sent-box-description-text">
                        {{ $t('sent.desc') }}
                    </div>
                    <div class="page-sent-box-actions">
                        <button class="page-sent-button" @click="downloadPDF()">
                            <DownloadIcon />

                            {{ $t('config.button1') }}
                        </button>
                        <button
                            class="page-sent-button page-sent-button--white"
                            @click="startNewNotes"
                            v-text="$t('sent.button')"
                        />
                    </div>
                </div>
            </div>
            <SummaryLinks theme="summary" />
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import DownloadIcon from '@/assets/images/system-icons/download_icon.svg'
import saveToPdf from '@/mixins/SaveToPdf'
import SummaryLinks from '@/components/summary/SummaryLinks.vue'
import NotesToCanvas from '@/mixins/NotesToCanvas'
export default {
    components: { SummaryLinks, DownloadIcon },
    mixins: [saveToPdf, NotesToCanvas],
    methods: {
        ...mapMutations(['clearConfiguration']),
        ...mapActions(['changePage', 'enableInMenu']),
        startNewNotes() {
            this.clearConfiguration()
            this.$root.$emit('navigationReset')
        },
    },
}
</script>

<style lang="scss">
.page-sent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &-box {
        @include size(857px, auto);
        box-shadow: 0 0 45px rgba(#000, 0.05);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 50px 70px;

        &-description {
            text-align: center;
            max-width: 600px;
            margin: 0 auto;
            &-title {
                margin-top: 0;
                font-size: 4.2rem;
                font-weight: bold;
            }
            &-text {
                font-size: 3rem;
            }
        }
        &-actions {
            display: inline-flex;
            gap: 10px;
        }
    }

    &-button {
        cursor: pointer;
        background-color: $color-burnt-sienna;
        border: 0;
        padding: 10px 20px 10px 20px;
        color: $color-white;
        font-size: 1.8rem;
        font-weight: 400;
        position: relative;
        margin-top: 30px;

        &--white {
            background-color: white;
            color: $color-burnt-sienna;
            border: 1px solid $color-burnt-sienna;
        }
    }

    @media (max-width: $screen-tablet-medium) {
        height: 100vh;
        > div {
            width: 90%;
            margin: 0 auto;
        }
        &-box {
            width: auto;
            margin: 0 auto;
            padding: 25px 35px;

            &-description {
                &-title {
                    font-size: 3rem;
                }
                &-text {
                    font-size: 2.2rem;
                }
            }
        }
    }
    @media (max-width: $screen-mobile-large) {
        &-box {
            &-actions {
                flex-direction: column;
                gap: 5px;
            }
        }
    }
}
</style>
