<template>
    <div class="page-config">
        <h1 class="page-title page-title-mobile page-config-title">
            {{ $t('config.title') }}
        </h1>
        <p class="page-config-subtitle">
            {{ $t('config.subtitle') }}
        </p>
        <div class="page-config-buttons">
            <button
                class="page-config-button page-config-buttons-expand"
                :class="{ 'page-config-button--active': showSummary }"
                @click="showSummary = !showSummary"
            >
                <ArrowIcon />
                {{ $t('config.details') }}
            </button>
            <button
                class="page-config-button page-config-buttons-pdf"
                :disabled="!configurationNo"
                @click="downloadPDF"
            >
                <DownloadIcon />
                {{ $t('config.button1') }}
            </button>
        </div>
        <TransitionExpand>
            <SummaryConfig v-if="showSummary" :summary="summaryPages" />
        </TransitionExpand>
        <SummaryLinks />

        <button
            class="form-submit send-button"
            :disabled="!configurationNo"
            @click="changePage('formPage')"
            v-text="$t('config.logowanko')"
        />

        <SummaryDisclimer
            v-if="['pl'].includes(locale)"
            :locale="locale"
            class="page-config-description"
        />
        <SummaryDisclimerLegacy
            v-else
            :locale="locale"
            class="page-config-description"
        />
    </div>
</template>

<script>
// libs
import { mapState, mapActions } from 'vuex'
// icons
import ArrowIcon from '@/assets/images/system-icons/arrow-down.svg'
import DownloadIcon from '@/assets/images/system-icons/download_icon.svg'
// components
import SummaryConfig from '@/components/summary/SummaryConfig'
import SummaryLinks from '@/components/summary/SummaryLinks'
import TransitionExpand from '@/components/transition/TransitionExpand'
// mixins
import SaveToPdf from '@/mixins/SaveToPdf'
import NotesToCanvas from '@/mixins/NotesToCanvas'
import SummaryDisclimerLegacy from '@/components/summary/SummaryDisclimerLegacy.vue'
import SummaryDisclimer from '@/components/summary/SummaryDisclimer.vue'

export default {
    name: 'YourConfig',
    components: {
        SummaryDisclimer,
        SummaryDisclimerLegacy,
        DownloadIcon,
        ArrowIcon,
        SummaryConfig,
        SummaryLinks,
        TransitionExpand,
    },
    mixins: [SaveToPdf, NotesToCanvas],
    data() {
        return {
            showSummary: false,
        }
    },
    computed: {
        ...mapState({
            configuration: (state) => state.notes.configuration,
            configurationApiCode: (state) => state.notes.configurationApiCode,
            isLoading: (state) => state.canvas.isLoading,
        }),
        configurationNo() {
            return this.configurationApiCode?.calculationId || null
        },
    },
    methods: {
        ...mapActions(['changePage']),
    },
}
</script>

<style lang="scss">
.page-config {
    max-height: 100%;
    margin-right: -20px;
    padding-right: 20px;
    padding-bottom: 62px;
    overflow-y: auto;
    @include hide-scrollbar();

    &-title {
        font-size: 4.8rem;
    }

    &-subtitle {
        font-size: 2.5rem;
    }

    &-button {
        border: 1px solid $color-silver;
        padding: 10px 20px;
        font-size: 2rem;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
            margin-right: 15px;
            transition: 0.1s;
            min-width: 25px;
            display: block;
        }

        &--active {
            svg {
                transform: rotate(180deg) translateX(8px);
            }
        }
    }

    &-buttons {
        display: flex;
        margin-top: 30px;
        margin-bottom: 35px;

        &-expand {
            background-color: transparent;
            width: 100%;
            text-align: left;
            justify-content: flex-start;
        }

        &-pdf {
            background-color: $color-burnt-sienna;
            color: $color-white;
            border-color: transparent;
            font-size: 1.8rem;
            display: flex;
            align-items: center;
            transition: background-color 0.3s;
            margin-left: 30px;
            min-width: 175px;

            &:hover {
                background-color: $color-burnt-sienna-light;
            }
        }
    }

    &-description {
        margin-top: 20px;

        h6 {
            font-size: 2.5rem;
            font-weight: 600;
            margin-bottom: 0.5em;
        }
        p {
            margin-bottom: 15px;
            font-size: 1.2rem;
            strong {
                font-weight: 600;
            }
        }
    }

    button[disabled] {
        opacity: 0.5;
        cursor: default;
    }

    @media (max-width: $screen-tablet-medium) {
        margin: 0 30px;
        max-height: unset;
        height: 100%;
        padding-top: 0;
    }
    @media (max-width: $screen-tablet-small) {
        &-title {
            font-size: 3.5rem;
        }
        &-subtitle,
        &-description h6 {
            font-size: 2.5rem;
        }
    }

    @media (max-width: $screen-mobile-large) {
        padding-right: 0;
        &-title {
            font-size: 3rem;
        }
        &-subtitle,
        &-description h6 {
            font-size: 2rem;
        }
        &-button {
            font-size: 1.6rem;
            display: flex;
            align-items: center;
            padding: 5px 13px;
        }
        &-buttons {
            &-expand {
                margin-right: 0;
            }
            &-pdf {
                margin-left: 10px;
            }
        }
    }
}
</style>
