<template>
    <ul class="navigation-list" :class="{ active: isReduced }">
        <li
            v-for="(element, index) in elements"
            :key="index"
            @click="redirect(element)"
        >
            <NavigationListElement
                v-if="!(element.isHiddenInMenu || element.disabledByDependency)"
                :icon="element.icon"
                :title="element.title"
                :color="element.color"
                :disabled="element.disabled || currentPage.disableConfigLinks"
                :is-active="currentPage.id === element.id"
            />
        </li>
    </ul>
</template>

<script>
import NavigationListElement from '@/components/navigation/NavigationListElement'
import { mapState, mapMutations } from 'vuex'

export default {
    components: {
        NavigationListElement,
    },
    props: {
        elements: {
            type: Array,
            default: () => {},
        },
    },
    computed: mapState({
        isReduced: (state) => state.menu.isReduced,
        currentPage: (state) => state.page.currentPage,
    }),
    methods: {
        ...mapMutations([
            'openModal',
            'setModalComponentId',
            'setCurrentSubOption',
        ]),
        redirect(element) {
            if (element.disabled || this.currentPage.disableConfigLinks) {
                return
            }
            this.openModal(false)
            this.setModalComponentId('')
            this.setCurrentSubOption(null)
            this.$emit('redirect', element.id)
        },
    },
}
</script>

<style lang="scss">
.navigation-list {
    padding-left: 80px;
    position: relative;
    top: 120px;
    list-style-type: none;
    margin-bottom: 20px;

    &.active {
        padding-left: 0;
    }

    @media (max-width: $screen-page) {
        top: 70px;
        padding-left: 70px;
    }

    @media (max-width: $screen-medium) {
        top: 40px;
    }

    @media (max-width: $screen-tablet-large) {
        padding-left: 40px;
    }
}
</style>
