<template>
    <div :class="['form form-individual', { 'in-process': inProcess }]">
        <div
            v-bastards
            class="form-description form-individual-description"
            v-text="$t('form.fill')"
        />
        <LoaderForm v-if="inProcess" />
        <form class="form-fields" @submit.prevent="goToOffer">
            <div class="form-individual-radio">
                <div class="form-individual-radio-element">
                    <input
                        id="mr"
                        v-model="payload.salutation"
                        type="radio"
                        name="salutation"
                        :value="$t('form.male')"
                    />
                    <label for="mr">{{ $t('form.male') }}</label>
                </div>

                <div class="form-individual-radio-element">
                    <input
                        id="mrs"
                        v-model="payload.salutation"
                        type="radio"
                        name="salutation"
                        :value="$t('form.female')"
                    />
                    <label for="mrs">{{ $t('form.female') }}</label>
                </div>
            </div>
            <FormFieldDefault
                ref="firstName"
                v-model="payload.firstName"
                name="firstName"
                :label="$t('form.name')"
                required
            />
            <FormFieldDefault
                ref="lastName"
                v-model="payload.lastName"
                name="lastName"
                :label="$t('form.surname')"
                required
            />
            <FormFieldPhone
                ref="phone"
                v-model="payload.phone"
                :default-code="locale"
                name="phone"
                type="tel"
                :label="$t('form.phone')"
                required
            />
            <FormFieldDefault
                ref="email"
                v-model="payload.email"
                name="email"
                type="email"
                :label="$t('form.email')"
                required
            />
            <FormFieldDefault
                :label="$t('form.country')"
                name="countryName"
                required
            >
                <FormDropdown
                    ref="country"
                    v-model="payload.countryName"
                    :options="countries"
                    theme="wide"
                />
            </FormFieldDefault>
            <FormFieldDefault
                ref="companyName"
                v-model="payload.companyName"
                name="companyName"
                :label="$t('form.company')"
                required
            />

            <FormFieldDefault
                ref="vatNr"
                v-model="payload.vatNr"
                name="vatNr"
                :label="$t('form.nip')"
                required
            />
            <FormCheckbox
                ref="PERMIT_ANSWER"
                v-model="payload.PERMIT_ANSWER"
                name="PERMIT_ANSWER"
                :label="$t('form.consent1')"
                required
            />
            <FormCheckbox
                ref="PERMIT_MAILING"
                v-model="payload.PERMIT_MAILING"
                name="PERMIT_MAILING"
                :label="$t('form.consent2')"
            />
            <FormIsRequiredInfo class="form-individual-required" />

            <button
                class="form-submit send-button"
                type="submit"
                :disabled="!formIsValid"
                v-text="$t('form.goToOffer')"
            />
        </form>
    </div>
</template>

<script>
import FormCheckbox from '@/components/form/input/FormCheckbox'
import FormMixin from '@/mixins/FormMixin'
import { mapActions, mapState } from 'vuex'
import FormIsRequiredInfo from '@/components/form/FormIsRequiredInfo'
import FormDropdown from '@/components/form/FormDropdown.vue'
import { countries } from '@/assets/data/countries'
import { individualFormDefaults } from '@/assets/data/form'
import FormFieldPhone from '@/components/form/input/FormFieldPhone.vue'

export default {
    components: {
        FormFieldPhone,
        FormDropdown,
        FormCheckbox,
        FormIsRequiredInfo,
    },
    extends: FormMixin,
    computed: {
        ...mapState({
            locale: (state) => state.i18n.locale,
            userData: (state) => state.userData,
        }),
        formIsValid() {
            return this.validation()
        },
        countries() {
            return [
                ...countries.map((c) => ({
                    value: c.code,
                    label: c.i18n[this.locale] || c.code,
                })),
                // {label: this.$t('offer.other'), value: 'inny'}
            ]
        },
    },
    methods: {
        ...mapActions(['changePage', 'sendLead']),
        setDefaultPayload() {
            this.payload = {
                ...individualFormDefaults,
                ...this.$store.state.user.userData,
            }
            if (!this.payload.salutation) {
                this.payload.salutation = this.$t('form.male')
            }
        },
        goToOffer() {
            if (!this.validation()) {
                this.$store.dispatch('showLoaderError', 'offer.error.form')
                return
            }

            this.$store.dispatch('showLoader', {
                message: 'offer.info.form',
                loading: true,
            })

            this.$store.commit('setUserData', this.payload)

            try {
                this.sendLead()
                    .then(() => {
                        this.changePage('offer')
                        this.$store.dispatch('hideLoader')
                    })
                    .catch(() => {
                        this.$store.dispatch('showLoaderError')
                    })
            } catch (e) {
                console.error(e)
                this.$store.dispatch('showLoaderError')
            }
        },
    },
}
</script>

<style lang="scss">
.form-individual {
    &-required {
        margin-left: 46px;
    }

    &-description {
        padding-top: 35px;
    }

    &-radio {
        display: flex;
        margin-bottom: 30px;

        &-element {
            margin-right: 30px;

            label {
                margin-left: 10px;
            }
        }
    }
    @media (max-width: $screen-tablet-medium) {
        display: flex;
        flex-direction: column;
        margin: 0 auto;

        &-required {
            margin-left: 60px;
        }
    }
}
</style>
