<template>
    <div class="workspace-arrows">
        <button
            v-if="previous && !current.hideButtonPrev"
            class="workspace-arrows-arrow workspace-arrows-arrow-left"
            @click="goToPrevPage"
        />
        <div v-if="showError && isLocked" class="workspace-arrows-error">
            {{ errorMessage }}
            <WarningIcon class="workspace-arrows-error-icon" />
        </div>
        <button
            v-if="next && !current.hideButtonNext"
            class="workspace-arrows-arrow workspace-arrows-arrow-right"
            :class="{ 'button-disabled': isLocked }"
            @click="goToNextPage"
        />
    </div>
</template>

<script>
import ChangePageMixin from '@/mixins/ChangePageMixin'
import WarningIcon from '@/assets/images/system-icons/warning-icon.svg'
import { mapState, mapMutations, mapActions } from 'vuex'
import NotesFormatMixin from '@/mixins/NotesFormatMixin'

export default {
    components: { WarningIcon },
    mixins: [ChangePageMixin, NotesFormatMixin],
    data() {
        return {
            showError: false,
        }
    },
    computed: {
        ...mapState({
            currentPage: (state) => state.page.currentPage,
        }),
        errorMessage() {
            return (
                this.$t(`errors.${this.currentPage.id}`) ||
                this.$t('errors.required')
            )
        },
    },
    watch: {
        isLocked(newVal) {
            if (newVal) {
                this.disableInMenu(this.currentPage.id)
            }
        },
    },
    methods: {
        ...mapMutations(['setLoading']),
        ...mapActions(['disableInMenu']),
        goToPrevPage() {
            this.showError = false
            this.goToPreviousPage()
        },
        goToNextPage() {
            if (!this.isLocked) {
                setTimeout(() => {
                    this.showError = false
                    this.changePageAction('next')
                    this.setLoading(false)
                })
            } else {
                this.showError = true
            }
        },
    },
}
</script>

<style lang="scss">
.workspace-arrows {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 42px;

    &-error {
        position: absolute;
        bottom: 42px;
        left: 56.15%;
        color: $color-burnt-sienna;
        padding: 13px 18px 13px 23px;
        background-color: rgba($color-burnt-sienna, 0.05);
        border: 1px solid;
        min-width: 343px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        pointer-events: none;

        &-icon {
            margin-left: 18px;
        }
    }

    &-arrow {
        @include size(48px);
        border: 2px solid $color-silver;
        cursor: pointer;
        transition: 0.1s;
        background-color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 42px;

        &::before {
            content: '';
            display: block;
            border: solid $color-silver;
            border-width: 0 0 2px 2px;
            @include size(10px);
        }

        &:hover:not(.button-disabled) {
            border-color: $color-burnt-sienna;
            background-color: $color-burnt-sienna;

            &::before {
                border-color: $color-white;
            }
        }

        &-left {
            left: 42px;

            &::before {
                margin-left: 5px;
                transform: rotate(45deg);
            }
        }

        &-right {
            right: 42px;
            border-color: $color-burnt-sienna;
            background-color: $color-burnt-sienna;

            &::before {
                margin-right: 5px;
                transform: rotate(-135deg);
                border-color: $color-white;
            }

            &:hover:not(.button-disabled) {
                background-color: $color-burnt-sienna-light;
                border-color: $color-burnt-sienna-light;
            }

            &.button-disabled {
                border-color: $color-silver;
                background-color: $color-white;
                opacity: 0.4;

                &::before {
                    border-color: $color-silver;
                }
            }
        }
    }
    @media (max-width: $screen-large) {
        &-error {
            left: 44%;
        }
    }
}
</style>
